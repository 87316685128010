<template>
	<a-modal v-model="clientFlag" title="选择客户" ok-text="确认" cancel-text="取消" @ok="assignHideModal" @cancel="cancelAssign">
		<a-input-search v-model="clientname" placeholder="根据客户名称搜索" enter-button="搜索" size="large" @search="clientSearch"  style="margin-bottom:20px"/>
		
		<div class="flex">
			<div style="width:55%;">
				<a-radio-group default-value="a" button-style="solid" style="margin:0 auto;" @change="choose">
					<a-radio-button value="a">按部门</a-radio-button>
					<a-radio-button value="b">按标签</a-radio-button>
				</a-radio-group>
				<a-tree :auto-expand-parent="true" @select="onSelect" @expand="onExpand" :tree-data="treeData"  v-if="treeFlag == true"/>
				<a-tree :auto-expand-parent="true" @check="onCheck2" checkable :tree-data="treeData2" :replaceFields="{children:'options', title:'name', key:'id'}" :defaultSelectedKeys="[]" v-else/>
			</div>
			
			<a-list item-layout="horizontal" :data-source="clientList" bordered style="width:40%;max-height:500px;overflow-y:scroll;margin-left:15px;">
				<a-list-item slot="renderItem" slot-scope="item,index" :style="item.isChecked ? 'background:#BAE7FF;':'background:#fff;'" @click="clientCheck(index)">
					{{item.tmjcpb_corp__name}}
					({{item.tmjcpb_department__name}}-{{item.tmjcpb_staff__name}})
				</a-list-item>
			</a-list>
		</div>
	</a-modal>
</template>
<script>
	import {requestXml} from '../assets/js/request';
	export default {
		props: {
			clientFlag: {
				type: Boolean,
				default: true
			},
			clientMode: {
				type: Number
			}
		}, 
		data() {
			return {
				// 选择客户
				visible: true,
				client: [],
				clientList: [],

				// 搜索的内容
				clientname: "",
				department_id: "",
				tagArr: [],

				treeFlag: true,
				treeData: [],
				treeData2: [],
				
				mode:0
			};
		},
		mounted: function() {
			this.getDepart();
			this.getTags();
		},
		watch:{
			clientMode: function(val){ 
				// console.log(val,8888888888)
				this.mode = val;
			},
		},
		methods: {
			choose(e){
				if(e.target.value == "a"){
					this.treeFlag = true;
					this.clientList = [];
				}else{
					this.treeFlag = false;
					this.clientList = [];
				}
			},
			// 获取部门
			getDepart(){
				requestXml("/scrm/Index/getDepartment","GET",(res) => {
					this.treeData = res;
				})
			},
			// 获取客户列表
			getClient(){
				for(let i=0;i<this.tagArr.length;i++){
					if(this.tagArr[i].indexOf("parent")>=0){
						this.tagArr.splice(i,1);
					}
				}
				requestXml("/scrm/Corp/getUnBindCorp", "POST", (res) => {
					console.log(res)
					for(let i=0;i<res.length;i++){
						res[i].isChecked = false;
					}
					this.clientList = res;
				},{"key":this.clientname,"tmjcpb_corp__department_id": this.department_id, "tag":this.tagArr} )
			},
			// 获取标签
			getTags(){
				requestXml("/scrm/corp/getTagOptions", "POST", (res) => {
					for(let i=0;i<res.length;i++){
						res[i].id = "parent" + res[i].id;
						for(let j=0;j<res[i].options.length;j++){
							res[i].options[j].name = res[i].options[j].value;
							res[i].options[j].id = res[i].options[j].value;
						}
					}
					console.log('res', res)
					this.treeData2 = res;
				})
			},
			clientCheck(idx){
				// mode为1客户是单选 为2客户是多选
				if(this.mode == 1){
					for(let i=0;i<this.clientList.length;i++){
						this.clientList[i].isChecked = false;
					}
					this.clientList[idx].isChecked = true;
				}else{
					if(this.clientList[idx].isChecked == false){
						this.clientList[idx].isChecked = true;
					}else{
						this.clientList[idx].isChecked = false;
					}
				}
				
			},
			clientSearch(){
				this.department_id = "";
				this.getClient();
			},
			onSelect(keys) {
				console.log(keys)
				this.clientname = "";
				this.tagArr = [];
				this.department_id = keys[0];
				this.getClient();
			},
			onExpand(keys) {
				console.log('Trigger Expand',keys);
			},
			onCheck2(keys) {
				this.clientname = "";
				this.department_id = "";
				this.tagArr = [...keys];
				this.getClient();
			},
			assignHideModal() {
				this.client = [];
				for(let i=0;i<this.clientList.length;i++){
					if(this.clientList[i].isChecked == true){
						this.client.push(this.clientList[i])
					}
				}
				this.$emit('updateClientFlag', this.client);
			},
			cancelAssign(){
				this.$emit('updateClientFlag', 1);
			}
        }
    }

</script>
<style scoped>
	.ant-list li {
		cursor: pointer;
	}
</style>